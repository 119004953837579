export default {
  bind(el, binding, vnode) {
    el.addEventListener(
      'blur', 
      (e) => {
        const value = e.target.value;
        if (value.length > e.target.maxLength) {
          e.preventDefault();
          e.target.value = value.slice(0, -1);
          el.value = value.slice(0, -1);

          let event;
          if (typeof Event === 'function') {
            event = new Event('input', { bubbles: true });
          } else {
            event = document.createEvent('Event');
            event.initEvent('input', true, true);
          }
          vnode.elm.dispatchEvent(event);
        }
      },
      true
    );
  },
};

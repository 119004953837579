import { CommonConstants } from '@/config/constant';

export default {
  bind(el, binding, vnode) {
    el.addEventListener(
      'blur',
      (e) => {
        let trimmedValue = e.target.value.trim();
        e.target.value = trimmedValue.replace(CommonConstants.EMOJI_REGEX, '');
        el.value = trimmedValue.replace(CommonConstants.EMOJI_REGEX, '');

        let event;
        if (typeof Event === 'function') {
          event = new Event('input', { bubbles: true });
        } else {
          event = document.createEvent('Event');
          event.initEvent('input', true, true);
        }
        vnode.elm.dispatchEvent(event);
      },
      true
    );
  },
};

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: '/eKYC/entry',
    meta: { title: 'ホーム' },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/eKYC/entry',
    name: 'EKYCEntry',
    component: () => import('../views/EKYC/Entry.vue'),
  },
  {
    path: '/eKYC/entry/code',
    name: 'EKYCEntryCode',
    component: () => import('../views/EKYC/EntryCode.vue'),
  },
  {
    path: '/eKYC/entry/confirm',
    name: 'EKYCEntryConfirm',
    component: () => import('../views/EKYC/EntryConfirm.vue'),
  },
  {
    path: '/eKYC/entry/finish',
    name: 'EKYCEntryFinish',
    component: () => import('../views/EKYC/EntryFinish.vue'),
  },
  {
    path: '/eKYC/entry/error/:entryCode',
    name: 'EKYCEntryError',
    component: () => import('../views/EKYC/EntryError.vue'),
  },
  {
    path: '/404',
    name: '404',
    meta: { title: '404' },
    component: () => import('../views/404.vue'),
  },
  {
    path: '*',
    redirect: '404',
  },
];

const title = 'ハウスペイ';

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

router.afterEach((to) => {
  document.title = to.meta.title + ' | ' + title || title;
});

export default router;

<template>
  <div id="app">
    <Loading v-if="isLoading"></Loading>
    <div class="wrapper" :class="{ 'is-open': drawerShow }">
      <!-- header -->
      <header v-if="headerDisplay && !headerNameOnly" class="header">
        <button v-if="headerBack && !isPincodeDefaultPage" class="header-btn" type="button" @click="historyBack">
          <i class="icon icon-back header-btn-icon"></i>
        </button>
        <button v-if="!headerBack" class="header-btn" type="button" @click="drawer">
          <i class="icon icon-drawer header-btn-icon"></i>
        </button>
        <template v-if="isheaderLogoShown">
          <h1 v-if="headerLogo" class="header-logo"><img class="header-logo-img" :src="headerLogo" /></h1>
          <h1 v-else class="header-headline">{{ eventName }}</h1>
        </template>
        <h1 v-else class="header-headline">{{ headerName }}</h1>
      </header>
      <header v-if="headerDisplay && headerNameOnly" class="header">
        <h1 class="header-headline">{{ headerName }}</h1>
      </header>
      <!-- /header -->
      <router-view @page-data="pageData" :is-logged-in="isLoggedIn" />
    </div>
    <div v-if="serverLocationShow" class="serverLocation">
      <p class="serverLocation-text">{{ serverLocation }}</p>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import { mapGetters } from 'vuex';
export default {
  name: 'App',
  data: function() {
    return {
      headerName: '',
      headerBack: true,
      headerDisplay: true,
      headerNameOnly: false,
      drawerShow: false,
      isheaderLogoShown: false,
      //serverLocation
      serverLocationShow: false,
      serverLocation: '',
      isLoggedIn: true,
    };
  },
  components: {
    Loading,
  },
  computed: {
    isPincodeDefaultPage() {
      return this.$route.name === 'PincodeDefault';
    },
    directory() {
      return this.$route.params.directory;
    },
    headerLogo() {
      return this.eventInfo?.headerLogo;
    },
    eventName() {
      return this.eventInfo?.name || '';
    },
    isTermsModalShown() {
      return this.$route.name !== 'Login';
    },
    ...mapGetters({
      isLoading: 'common/isLoading',
    }),
  },
  methods: {
    logout: async function() {
      this.drawerShow = false;
      await this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'Home' });
    },
    drawer: function() {
      this.drawerShow = !this.drawerShow;
    },
    closeDrawer: function() {
      this.drawerShow = false;
    },
    pageData: function(pageName, pageBack, pageLogo, display, pageNameOnly) {
      this.headerName = pageName;
      //back
      if (typeof pageBack === 'undefined') {
        this.headerBack = true;
      } else {
        this.headerBack = false;
      }
      //logo
      if (typeof pageLogo === 'undefined') {
        this.isheaderLogoShown = false;
      } else {
        this.isheaderLogoShown = true;
      }
      //display
      if (typeof display === 'undefined') {
        this.headerDisplay = true;
      } else {
        this.headerDisplay = false;
      }
      //name
      if (typeof pageNameOnly === 'undefined') {
        this.headerNameOnly = false;
      } else {
        this.headerNameOnly = true;
      }
    },
    height: function() {
      let winH = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${winH}px`);
    },
    resize: function() {
      this.height();
    },
  },
  created() {
    this.height();
    //serverLocation
    const url = location.origin;
    if (url.match(/localhost/)) {
      this.serverLocationShow = true;
      this.serverLocation = 'localhost';
    }
    if (navigator.userAgent.includes('PalletApp')) {
      this.$store.commit('common/SET_IS_WEB_VIEW', true);
    }
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    $route: function(to, from) {
      if (to.path !== from.path) {
        if (this.drawerShow) {
          this.closeDrawer();
        }
      }
    },
  },
};
</script>

export class CommonConstants {
  static NUMBER_VALIDATION_REGEX = /[^0-9]/g;
  static SPACE_REGEX = /\s/g;
  static EMOJI_REGEX = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g;
  static GENDERS = [
    { value: 'male', label: '男性' },
    { value: 'female', label: '女性' },
  ];
  static DATE_FORMAT = 'yyyy年M月d日';
  static DATE_FORMAT_CUSTOM = 'yyyy/MM/dd';
  static DATETIME_WITHOUT_YEAR_FORMAT = 'MM/dd H:mm';
  static DATETIME_FORMAT = 'yyyy年M月d日 H:mm';
  static DATETIME_FORMAT_CUSTOM = 'yyyy/MM/d H:mm:ss';
}

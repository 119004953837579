<template>
  <footer class="footer" :class="{ 'is-copyonly': !menu }">
    <div class="footer-menu" v-if="menu">
      <router-link class="btn btn-bd-main footer-inquiry" to="">お問合せはこちら</router-link>
      <nav class="footer-nav">
        <ul class="footer-nav-set">
          <li class="footer-nav-item"><router-link class="footer-nav-link" to="">利用規約</router-link></li>
          <li class="footer-nav-item"><router-link class="footer-nav-link" to="">プライバシーポリシー</router-link></li>
          <li class="footer-nav-item"><router-link class="footer-nav-link" to="">ヘルプ</router-link></li>
        </ul>
      </nav>
    </div>
    <p class="footer-copy">&copy;GMO DIGITAL Lab K.K. All Rights Reserved.</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    menu: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
//historyback
import historyBack from './mixins/historyBack';
Vue.mixin(historyBack);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

//global filter
//add commma
//https://qiita.com/msht0511/items/f285383dc28bd3666bfe
//http://www.sky-limit-future.com/entry/vuefilter_addcomma
import { getPhoneNumber, getZipCode } from './filters/phoneNumber';
import { getShortDate, getShortDateCustom, getFullDate, getFullDateCustom } from './filters/date';
Vue.filter('shortDate', getShortDate);
Vue.filter('shortDateCustom', getShortDateCustom);
Vue.filter('fullDate', getFullDate);
Vue.filter('fullDateCustom', getFullDateCustom);
Vue.filter('phoneNumber', getPhoneNumber);
Vue.filter('zipcode', getZipCode);

//global directives
import TrimDirective from './directives/trim';
import NumberDirective from './directives/number';
import MaxLenghtDirective from './directives/maxlength';
import KatakanaDirective from './directives/katakana';
import SpaceDirective from './directives/space';
Vue.directive('trim', TrimDirective);
Vue.directive('number', NumberDirective);
Vue.directive('maxlength', MaxLenghtDirective);
Vue.directive('katakana', KatakanaDirective);
Vue.directive('space', SpaceDirective);

//global prototype
import loading from '@/helpers/loading';
Vue.prototype.$loading = loading;

//footer
import Footer from './components/Footer.vue';
Vue.component('Footer', Footer);
//action button
import ActionButton from './components/ActionButton.vue';
Vue.component('ActionButton', ActionButton);

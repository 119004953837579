<template>
  <button :disabled="disabled || isButtonLoading" :type="buttonType" :class="className" @click="handleSubmit">
    {{ buttonText }}
    <slot></slot>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ActionButton',
  props: {
    className: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonType: {
      type: String,
      default: 'button'
    },
    handleSubmit: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isButtonLoading: 'common/isButtonLoading'
    })
  },
};
</script>

import store from '@/store';
import { CommonConstants } from '@/config/constant';

const loading = {
  show: () => {
    return setTimeout(() => {
      store.dispatch('common/setLoading', true);
    }, CommonConstants.LOADING_TIME);
  },
  clear: (loading) => {
    store.dispatch('common/setLoading', false);
    clearTimeout(loading);
  },
};

export default loading;

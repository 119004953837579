import axios from 'axios';
import store from '@/store';
import loading from '@/helpers/loading';

let http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  withCredentials: false,
  timeout: 30000,
});

let timtoutId;
http.interceptors.request.use((config) => {
  config.headers = {
    'X-Api-Key': process.env.VUE_APP_API_KEY_EKYC,
  };
  store.dispatch('common/setButtonLoading', true);
  timtoutId = loading.show();
  return config;
});

http.interceptors.response.use(
  (response) => {
    store.dispatch('common/setButtonLoading', false);
    if (timtoutId) {
      loading.clear(timtoutId);
    }
    return response;
  },
)

export default http;
import http from "../../../config/api";

export default {
  async setEKYCInfo() {
    return await http.post('').then(
      (res) => res.data,
      (err) => err
    );
    
  },
  resetRegistInfo({ commit }) {
    sessionStorage.removeItem('registInfo');
    commit('RESET_REGIST_INFO');
  },
};

export default {
  setButtonLoading({ commit }, isButtonLoading) {
    return commit('SET_BUTTON_LOADING', isButtonLoading);
  },
  openErrorModal({ commit }, isErrorModalOpened) {
    return commit('OPEN_ERROR_MODAL', isErrorModalOpened);
  },
  setErrors({ commit }, errors) {
    return commit('SET_ERRORS', errors);
  },
  setLoading({ commit }, isLoading) {
    return commit('SET_LOADING', isLoading);
  },
}

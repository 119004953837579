<template>
  <div class="loading">
    <div class="loading-content">
      <p class="loading-text">読み込み中</p>
      <vue-loading class="loading-spinner" type="spin" :size="{ width: '50px', height: '50px' }"></vue-loading>
    </div>
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template';

export default {
  props: {},
  components: {
    VueLoading,
  },
};
</script>

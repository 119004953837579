import { format } from 'date-fns';
import { CommonConstants } from '@/config/constant';

function getShortDate(value) {
  if (!value) return;
  return format(new Date(value), CommonConstants.DATE_FORMAT);
}

function getShortDateCustom(value) {
  if (!value) return;
  return format(new Date(value), CommonConstants.DATE_FORMAT_CUSTOM);
}

function getDatetimeWithoutYear(value) {
  if (!value) return;
  return format(new Date(value), CommonConstants.DATETIME_WITHOUT_YEAR_FORMAT);
}

function getFullDate(value) {
  if (!value) return;
  return format(new Date(value), CommonConstants.DATETIME_FORMAT);
}

function getFullDateCustom(value) {
  if (!value) return;
  return format(new Date(value), CommonConstants.DATETIME_FORMAT_CUSTOM);
}

export { getShortDate, getFullDate, getFullDateCustom, getShortDateCustom, getDatetimeWithoutYear };

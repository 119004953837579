import Vue from 'vue';
import Vuex from 'vuex';
import commonModule from './modules/common';
import customerModule from './modules/customer';
import eKYCModule from './modules/eKYC';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    common: commonModule,
    customer: customerModule,
    eKYC: eKYCModule,
  },
});

export default store;

export default {
  SET_BUTTON_LOADING(state, isButtonLoading) {
    state.isButtonLoading = isButtonLoading;
  },
  OPEN_ERROR_MODAL(state, isErrorModalOpened) {
    state.isErrorModalOpened = isErrorModalOpened;
  },
  SET_ERRORS(state, errors) {
    state.errors = errors;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
};
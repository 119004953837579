import { getInputEvent } from '@/helpers/device';
import { CommonConstants } from '@/config/constant';

export default {
  bind(el) {
    const inputEvent = getInputEvent();
    el.addEventListener(
      inputEvent,
      (e) => {
        e.target.value = e.target.value.replace(CommonConstants.SPACE_REGEX, '');
      },
      true
    );
  },
};

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state() {
    return {
      registInfo: JSON.parse(sessionStorage.getItem('registInfo')) || {},
    };
  },
  mutations,
  actions,
  getters,
  namespaced: true,
};
